import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Grid, Button,Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import Bank from "../../Images/kyc/bank.svg";
import Demat from "../../Images/demat.svg";
import User from "../../Images/user-box-line.svg";
import FAQs from "../../Images/FAQs.svg";
import Chat from "../../Images/Chat.svg";
import Logout from "../../Images/Logout.svg";
import Edit from "../../Images/Edit.svg";
import legal from "../../Images/legal.svg";
import terms from "../../Images/terms.svg";
import Support from "../../Images/home/support.svg";
import Privacy from "../../Images/Privacy.svg";
import NoReturn from "../../Images/NoReturn.svg";
import Risks from "../../Images/Risks.svg";
import Visibilityoff from "../../Images/Visibilityoff.svg";
import Visibility from "../../Images/Visibility.svg";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import BottomNavigation from "../../component/Home/BottomNavigation";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { connect } from "react-redux";
import { logoutSuccess } from "../../../src/store/bugs";
import { userService } from "../../../src/_services/users";
import { kycService, getUserBasicKycDetails } from "../../_services/kyc";
import CheckCircleOutlineIcon from "../../Images/verified.svg";
import SessionExpiredModal from '../../component/AlertModel/SessionExpiredModal';

const useStyles = makeStyles((theme) => ({
    boxcontainer: {
        minHeight: "calc(100vh - 77px) !important",
        background: "#0D0D0D !important",
        maxWidth: "100% !important",
        overflowX: "hidden !important",
    },
    name: {
        textTransform: "capitalize !important",
        marginBottom: " 10px !important",
        color: "#FFF !important",
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: " 600 !important",
        fontSize: " 24px !important",
        lineHeight: "36px !important",
    },
    number: {
        marginBottom: " 6px !important",
        color: "#AC93FF!important",
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: " 500 !important",
        fontSize: " 12px !important",
        lineHeight: "18px !important",
    },
    mail: {
        marginBottom: " 0px !important",
        color: "#fff!important",
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: " 400 !important",
        fontSize: " 12px !important",
        lineHeight: "18px !important",
    },

    texts: {
        color: "#A489FF !important",
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: " 400 !important",
        fontSize: " 10px !important",
        lineHeight: "15px !important",
        padding: "0px 6px 16px 6px !important",
        textDecoration: "underline !important",
        textTransform: "initial !important",
    },

    img: {
        // alignItems:" center important ",
        // justifyContent: "center important ",
        // display: "flex important ",
        width: "40px!important ",
        height: "40px !important",
        borderRadius: "15px !important",
        background: "#1C1C1C !important",
        padding: "12px 11px !important",
        margin: "0px !important",
    },
    accordion: {
        margin: "24px 16px 36px 16px!important",
        "& .MuiAccordion-root": {
            backgroundColor: " #1C1C1C !important",
            boxShadow: "none !important",
            borderRadius: "10px !important",
        },
    },
    accordionSummary: {
        minHeight: "0px !important",
        "& .MuiAccordionSummary-root": {
            color: "#fff !important",
            justifyContent: " space-between !important",
            alignItems: "center !important "
        },
        "& .Mui-expanded ": {
            // minHeight:" 0px !important",
            margin: "16px 0px !important",
        },
        "& .MuiAccordionSummary-content": {
            margin: "16px 0px! important"
        }
    },
    subtitle: {
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "12px !important",
        color: "#fff",
        lineHeight: "18px !important",
    },
    text: {
        color: "#fff",
        fontFamily: "Poppins !important",
        fontStyle: "normal !important",
        fontWeight: " 500 !important",
        fontSize: " 14px !important",
        lineHeight: "21px !important",
    },
    dialogSubTitle: {
        fontFamily: "Poppins  !important",
        fontStyle: "normal  !important",
        fontWeight: "400  !important",
        fontSize: "14px  !important",
        color: "#F4F4F4 !important",
        lineHeight: "18px !important",
        textAlign: "center !important",
        // margin:"1rem !important",
        padding:"1rem !important"
      },
      dialogBtn:{
        borderRadius:"10px !important",
        fontSize: "14px  !important",
        fontFamily: "Poppins  !important",
        textTransform: "uppercase !important",
        fontWeight: "500 !important",
        lineHeight:"14px  !important"
    },
    cards: {
      "& 	.MuiDialog-paper": {
        width: "241px !important",
        borderRadius: "10px !important",
        border: "solid 1px transparent !important",
        backgroundImage:
          "linear-gradient(#AB97EB, #583EAC), linear-gradient(180deg, #AB97EB 0%, #583EAC 100%) !important",
        backgroundOrigin: "border-box !important",
        backgroundClip: "content-box, border-box !important",
        boxShadow: "2px 1000px 1px #0D0D0D inset  !important",
        // padding: "16px 0px !important",
        // height:"136px !important"
      },
    },
}));

function Profile(props) {

    const navigate = useNavigate();

    const classes = useStyles();
    const userID =
        props.authReducer &&
        props.authReducer.auth &&
        props.authReducer.auth.user &&
        props.authReducer.auth.user.id;
    const [userBasicInfo, setUserBasicInfo] = useState("");
    const [bankAccountData, setBankAccountData] = useState(false);
    const [dematData, setDematData] = useState([]);
    const slides = localStorage.getItem("KYCSlides")
    const location = useLocation()
    const expand = location && location.state && location.state.condition
    const [expanded, setExpanded] = React.useState(expand ? 'panel1' : false);
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
    const [kycStatus, setKycStatus] = useState()
  const [open, setOpen] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel :  false);
    };
    const [userNumber, setUserNumber] = useState("");

    const logout = () => {
        props.logoutSuccess();
        navigate("/", { replace: true });
    };

    const getUserBasicKycDetails = () => {
        kycService
            .getUserBasicKycDetails()
            .then((res) => {
                if (res) {
                    if (res.status === 200) {
                        if (res.data) {
                            setKycStatus(res.data.data.display_status);
                        }
                    } else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true);
                        }
                    }
                }
            })
            .catch((error) => console.log(error));
    }

    const getDetails = () => {

        if (userID) {
            userService
                .getUserDetails(userID)
                .then((res) => {
                    if (res) {
                        if (res.status === 200) {
                            setUserBasicInfo(res.data[0]);
                            let mobile = res.data[0].contact_no.startsWith('+') ? res.data[0].contact_no : '+91' + res.data[0].contact_no
                            let number =mobile.replace(/^(.{3})(.{5})(.*)$/, "$1 $2 $3") 
                            setUserNumber(number)

                        } else {
                            if (res.status === 401 && res.data.name === "TokenExpiredError") {
                                setOpenSessionExpiredModal(true)
                            }
                        }
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    const getBankAccounts = () => {
        userService
            .getUserBankAccounts(userID)
            .then((res) => {
                if (res) {
                    if (res.status === 200) {
                        setBankAccountData(res.data[0]);
                    } else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                        }
                    }
                }
            })
            .catch((err) => console.log(err));
    };

    const getDematAccounts = () => {
        userService
            .getUserDemat(userID)
            .then((res) => {
                if (res) {
                    if (res.status === 200) {
                        setDematData(res.data[0]);
                    } else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                        }
                    }
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getDetails();
        getBankAccounts();
        getDematAccounts();
        // getKycDetails();
        getUserBasicKycDetails();
        if(location.state){
            const element = document.getElementById("content");
            element.scrollIntoView();
           }
    }, [userID]);
    const toTitleCase = str => str.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase())

    return (
        <div>
            <Page>
                <Box className={classes.boxcontainer}>
                    <Grid container style={{ display: "flex", padding: "26px 16px" }}>
                        <Grid item xs={11} style={{ alignItems: "center" }}>
                            {userBasicInfo.code === "COMPANY" ? (
                                <Typography className={classes.name} variant="h3" gutterBottom>
                                    {userBasicInfo && `${userBasicInfo.company_name}`}
                                </Typography>
                            ) : (
                                <Typography className={classes.name} variant="h3" gutterBottom>
                                    {userBasicInfo &&
                                        `${toTitleCase(userBasicInfo.first_name)} ${toTitleCase(userBasicInfo.last_name)}`}
                                </Typography>
                            )}
                            <Typography className={classes.number} variant="h6" gutterBottom>
                                {userNumber &&
                                    `${userNumber}`}
                            </Typography>
                            <Typography className={classes.mail} variant="h6" gutterBottom>
                                {userBasicInfo.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: "-webkit-right" }}>
                            <img
                                src={Edit}
                                alt=""
                                style={{
                                    paddingTop: "45px",
                                    fontSize: "1rem",
                                    color: "#AC93FF",
                                }}
                                onClick={() =>
                                    navigate("/editProfile", {
                                        replace: true,
                                    })
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid item >
                        <hr
                            style={{
                                backgroundColor: "#1C1C1C",
                                border: "none",
                                height: "1px",
                                margin: "0px"
                            }}
                        />
                    </Grid>
                    <Grid
                        container
                        onClick={() =>
                            bankAccountData &&
                            navigate("/edit", {
                                replace: true,
                                state: {
                                    type: "bankDetail",
                                    bankDetail: bankAccountData,
                                },
                            })
                        }
                        style={{ display: "flex", alignItems: "center", padding: "17px 16px" }}
                    >
                        <Grid item xs={6} style={{ display: "flex", alignItems: "center" }} >
                            <img src={Bank} alt="" className={classes.img} />
                            <Typography
                                ml={2}
                                className={classes.text}
                                variant="h6"
                                style={{ color: bankAccountData ? "#fff" : "#767677" }}
                            >
                                Bank
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <ArrowForwardIosIcon
                                style={{ fill: "#fff", fontSize: "0.9rem" }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <hr
                            style={{
                                backgroundColor: "#1C1C1C",
                                border: "none",
                                height: "1px",
                                margin: "0px"
                            }}
                        />
                    </Grid>
                    <Grid
                        container
                        onClick={() => {
                            if (dematData) {
                                navigate("/edit", {
                                    replace: true,
                                    state: {
                                        type: "dematDetail",
                                        dematDetail: dematData,
                                    },
                                })
                            } else {
                                window.open('https://onboard.northeastltd.com', '_blank')
                            }
                        }

                        }
                        style={{ display: "flex", alignItems: "center", padding: "20px 16px", }}
                    >
                        <Grid item xs={5} style={{ height: "40px", display: "flex", alignItems: "center" }}>
                            <img src={Demat} alt="" width={15} className={classes.img} />
                            <Typography
                                ml={2}
                                className={classes.text}
                                style={{ color: dematData ? "#fff" : "#767677" }}
                                variant="h6"

                            >
                                Demat
                            </Typography>
                        </Grid>

                        <Grid item xs={7} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            {dematData ? (
                                ""
                            ) : (
                                <Typography
                                    className={classes.text}
                                    style={{ color: "#AC93FF" }}
                                    variant="h6"
                                >
                                    CREATE DEMAT A/C
                                </Typography>
                            )}
                            <ArrowForwardIosIcon

                                style={{ fill: "#fff", fontSize: "0.9rem", marginLeft: "27px", }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <hr
                            style={{
                                backgroundColor: "#1C1C1C",
                                border: "none",
                                height: "1px",
                                margin: "0px"
                            }}
                        />
                    </Grid>
                    <Grid
                        container

                        onClick={() => {
                            if (kycStatus === "Pending" && !slides) {
                                navigate("/kycslides")
                            } else {
                                navigate("/status", {
                                    replace: true,
                                    state: { status: kycStatus }
                                })
                            }
                        }}
                        style={{ display: "flex", justifyContent: "space-between", padding: "20px 16px" }}
                    >
                        <Grid item xs={6} md={6} style={{ height: "40px", display: "flex", alignItems: "center" }}>
                            <img src={User} alt="" width={15} className={classes.img} />
                            <Typography
                                ml={2}
                                className={classes.text}
                                style={{ color: kycStatus === 'Pending' ? "#767677" : "" }}
                                variant="h6"

                            >
                                KYC
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} style={{ textAlign: "right", display: "flex", alignItems: "center", justifyContent: "end" }}>
                            {kycStatus === 'Pending' ? (
                                <Grid>
                                    <Typography
                                        className={classes.text}
                                        style={{ color: "#AC93FF" }}
                                        variant="h6"
                                    >
                                        Start KYC
                                    </Typography>
                                </Grid>
                            ) : kycStatus === 'In Progress' ? (
                                <Grid>
                                    <Typography
                                        className={classes.text}
                                        style={{ color: "#AC93FF" }}
                                        variant="h6"
                                    >
                                        Continue
                                    </Typography>
                                </Grid>
                            ) : kycStatus === 'Approved' ? (
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                    <img
                                        src={CheckCircleOutlineIcon}
                                        alt="verified"
                                        width="14"
                                        height="14"
                                    />

                                    <Typography
                                        className={classes.text}
                                        style={{ color: "#7BDE89", margin: "0px 0px 0px 10px " }}
                                    >
                                        Verified
                                    </Typography>
                                </Grid>
                            ) : ""}
                            <ArrowForwardIosIcon

                                style={{ fill: "#fff", fontSize: "0.9rem", marginLeft: "27px" }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <hr
                            style={{
                                backgroundColor: "#1C1C1C",
                                border: "none",
                                height: "1px",
                                margin: "0px"
                            }}
                        />
                    </Grid>
                    <Grid
                        container
                        onClick={() => navigate("/FAQs")}
                        style={{ display: "flex", alignItems: "center", padding: "19px 16px" }}
                    >
                        <Grid item xs={6} md={6} style={{ height: "40px", display: "flex", alignItems: "center" }}>
                            <img src={FAQs} alt="" width={15} className={classes.img} />
                            <Typography
                                ml={2}
                                className={classes.text}
                                variant="h6"

                            >
                                FAQs
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                            <ArrowForwardIosIcon

                                style={{ fill: "#fff", fontSize: "0.9rem" }}
                            />
                        </Grid>
                    </Grid>
                    
                    <Grid item>
                        <hr
                            style={{
                                backgroundColor: "#1C1C1C",
                                border: "none",
                                height: "1px",
                                margin: "0px"
                            }}
                        />
                    </Grid>
                    <Grid
                        container
                        onClick={() =>
                            window.open("https://wa.me/918951807103", "_blank")
                        }
                        style={{ display: "flex", alignItems: "center", padding: "19px 16px" }}
                    >
                        <Grid item xs={6} style={{ height: "40px", display: "flex", alignItems: "center" }} >
                            <img src={Chat} alt="" width={15} className={classes.img} />
                            <Typography
                                ml={2}
                                className={classes.text}
                                variant="h6"

                            >
                                Chat with us
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <ArrowForwardIosIcon

                                style={{ fill: "#fff", fontSize: "0.9rem" }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <hr
                            style={{
                                backgroundColor: "#1C1C1C",
                                border: "none",
                                height: "1px",
                                margin: "0px"
                            }}
                        />
                    </Grid>
                    <Grid
                        container
                        onClick={()=>setOpen(true)}
                        style={{ display: "flex", alignItems: "center", padding: "19px 16px" }}
                    >
                       <Grid item xs={6} style={{ height: "40px", display: "flex", alignItems: "center" }} >
                            <img src={Logout} alt="" width={15} className={classes.img} />
                            <Typography ml={2} className={classes.text} variant="h6">
                                Logout
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <hr
                            style={{
                                backgroundColor: "#1C1C1C",
                                border: "none",
                                height: "1px",
                                margin: "0px"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.accordion} id="content">
                        <Accordion
                            expanded={expanded === "panel1" }
                            onChange={handleChange("panel1")}
                        >
                            <AccordionSummary
                                expandIcon={
                                    expanded === "panel1" ? (
                                        <img
                                            src={Visibility}
                                            style={{ color: "#A489FF", width: "22px" }}
                                        />
                                    ) : (
                                        <img
                                            src={Visibilityoff}
                                            style={{ color: "#494949", width: "23px" }}
                                        />
                                    )
                                }
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className={classes.accordionSummary}
                            >
                                <Typography className={classes.subtitle}>
                                    Policy References
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ display: "inline-block", marginRight: "10px", padding: "8px 16px" }}
                            >
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Button
                                            onClick={() =>
                                                navigate("/legalpolicy", { replace: true })
                                            }
                                            disableRipple
                                            className={classes.texts}
                                            startIcon={
                                                <img src={legal} alt="" width={10} height={10} />
                                            }
                                        >
                                            Legal & Compliance
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Button
                                            onClick={() =>
                                                navigate("/subtermsandconditions", { replace: true })
                                            }
                                            disableRipple
                                            className={classes.texts}
                                            startIcon={
                                                <img src={terms} alt="" width={10} height={10} />
                                            }
                                        >
                                            Terms & Conditions
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Button
                                            onClick={() =>
                                                navigate("/privacyandpolicy", { replace: true })
                                            }
                                            disableRipple
                                            className={classes.texts}
                                            startIcon={
                                                <img src={Privacy} alt="" width={10} height={10} />
                                            }
                                        >
                                            Privacy Policy & Security
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Button
                                            onClick={() =>
                                                navigate("/risksanddisclosures", { replace: true })
                                            }
                                            disableRipple
                                            className={classes.texts}
                                            startIcon={
                                                <img src={Risks} alt="" width={10} height={10} />
                                            }
                                        >
                                            Risk Disclosure & Compliance
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Button
                                            onClick={() =>
                                                navigate("/noreturnpolicy", { replace: true })
                                            }
                                            disableRipple
                                            className={classes.texts}
                                            startIcon={
                                                <img src={NoReturn} alt="" width={10} height={10} />
                                            }
                                        >
                                            Return & Exchange Policy
                                        </Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Box>
            </Page>
               <Dialog onClose={()=>setOpen(false)} open={open} className={classes.cards}>
          <Grid item xs={12} md={12}>
          <Typography className={classes.dialogSubTitle}>
          Are you sure <br/> you want to logout?
        </Typography>
          </Grid>
            <Grid container style={{display:"flex"}}>
           <Grid item xs={6} md={6} style={{ borderTop:"1px solid #3B3159 ", height:"40px", display:"flex", justifyContent:'center'}}>
           < Button className={classes.dialogBtn}
           disableRipple
           style={{color:"#A489FF"}}
            onClick={logout}
            >
           Yes
            </  Button>
           </Grid>
           <Grid item xs={6} md={6} style={{ borderTop:"1px solid #3B3159 ",borderLeft:"1px solid #3B3159 ", height:"40px", display:"flex", justifyContent:'center'}}>
            < Button className={classes.dialogBtn} style={{color:"#8C8D8D"}}
            disableRipple
            onClick={()=>{
              setOpen(false)
            }}
            >
           No
            </  Button>
          </Grid>
          </Grid>
        </Dialog>
            <SessionExpiredModal
                open={openSessionExpiredModal}
            />
            <BottomNavigation activeTab={2} />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        force: state,
        authReducer: state.authReducer,
    };
};

const dispatchToProps = (dispatch) => ({
    logoutSuccess: () => dispatch(logoutSuccess()),
});
export default connect(mapStateToProps, dispatchToProps)(Profile);