import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../component/Page/Page";
import Tabs from "../../component/Home/Tabs";
import BottomNavigation from "../../component/Home/BottomNavigation";
import KycBand from "../../component/Home/KycBand";
import {userService} from '../../_services/users'
const useStyles = makeStyles((theme) => ({
  boxcontainer: {
    minHeight: "calc(100vh - 77px) !important",
    background: "#0D0D0D !important",
  },
}));

function Home() {
  const classes = useStyles();

  React.useEffect(() => {
    document.addEventListener("fcmMessage", async function (e) {
       if(e.data.fcmToken){
        let data = {
        fcm_token:e.data.fcmToken,
        deviceType:e.data.deviceType
       }
   await userService.updateFcmToken(data)
        }else{
        console.log("no msg")
      }
  });
    return () =>
      document.removeEventListener("fcmMessage", function (e) {
      });
  }, []);

  // if(window.ReactNativeWebView != undefined ){
  //   let data = {
  //     getFcmToken:true
  //   }
  //   window.ReactNativeWebView.postMessage(JSON.stringify(data));
  //   console.log("post message sent")
  // }else{
  //  console.log("no msg")
  // }

  return (
    <div>
      <Page>
        <Box className={classes.boxcontainer}>
          <KycBand />
          <Tabs/>
        </Box>
      </Page>
      <BottomNavigation activeTab={0}  />
    </div>
  );
}


export default Home;
